import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { BLACK, DefaultColors, PRIMARY } from '../../constants/colors'

interface AvatarProps {
  width: number
  color?: string
}

const Container = styled.div<AvatarProps>`
  width: ${({ width }) => width}px;
  height: ${({ width }) => width}px;
  border-radius: ${({ width }) => width / 2}px;
  border: 3px solid ${BLACK};
  background-color: ${({ color }) => color};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const Initials = styled.div<AvatarProps>`
  text-align: center;
  font-size: ${({ width }) => width / 2}px;
  color: ${PRIMARY};
`

interface Props {
  width: number
  initials: string
  userId: number
}

class DefaultAvatar extends PureComponent<Props> {
  render() {
    const { width, initials, userId } = this.props

    const index = userId % 15

    return (
      <Container width={width} color={DefaultColors[index]}>
        <Initials width={width}>{initials}</Initials>
      </Container>
    )
  }
}

export default DefaultAvatar
