import styled from 'styled-components'
import { PRIMARY } from '../constants/colors'

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 48px;
`

export const HeaderInfo = styled.div`
  margin-left: 18px;
  display: flex;
  flex-direction: column;
`

export const ProfilePicture = styled.img`
  height: 100px;
  width: 100px;
  border-radius: 50%;
  object-fit: cover;
  overflow: hidden;
  margin-bottom: 0px;
`

export const ProfilePrimary = styled.div`
  font-size: 30px;
  font-weight: bold;
  line-height: 40px;
  letter-spacing: 0.5px;
  color: ${PRIMARY};
`

export const ProfileSecondary = styled.div`
  font-size: 22px;
  font-weight: 100;
  line-height: 30px;
  letter-spacing: 0.5px;
  color: ${PRIMARY};
`

export const SectionHeader = styled.div`
  font-size: 22px;
  color: ${PRIMARY};
  letter-spacing: 1px;
  margin-left: 8px;
  margin-bottom: 28px;
`

// export const WorkoutPreview = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: flex-start;
//   max-height: 100px;
//   margin-bottom: 30px;
// `

// export const WorkoutPreviewThumbnailWrap = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `

// export const WorkoutPreviewThumbnail = styled.img`
//   border-radius: 10px;
//   object-fit: cover;
//   width: 100px;
//   height: 75px;
//   margin-bottom: 0px;
// `

// export const WorkoutInfoWrap = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   flex: 4;
//   padding-left: 22px;
//   padding-right: 88px;
// `

// export const WorkoutInfoPrimary = styled.div`
//   font-size: 18px;
//   line-height: 20px;
//   color: ${PRIMARY};
//   font-weight: 400;
// `

// export const WorkoutInfoSecondary = styled.div`
//   font-size: 15px;
//   line-height: 20px;
//   color: ${PRIMARY};
//   font-weight: 200;
// `

// export const CheveronRightWrap = styled.div`
//   display: flex;
//   flex: 1;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
// `

export const SectionWrap = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-bottom: 36px;
`

// export const SeeAllText = styled.div`
//   font-size: 18px;
//   line-height: 30px;
//   color: ${SECONDARY};
//   letter-spacing: 1.5px;
//   text-transform: uppercase;
// `
