import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import * as S from '../styles/profile.styles'
import Layout from '../templates/Layout'
import WorkoutsRow from '../components/WorkoutsRow'
import * as session from '../services/session'
import { CLIENT_NAME, HOME_ROUTE } from '../constants/routes'
import DefaultAvatar from '../components/shared/DefaultAvatar'
import { Section } from '../components/shared'
import { RootState } from '../redux/reducers/rootReducers'
import {
  onFetchAthleteProfile as onFetchAthleteProfileAction,
  onFetchLikedWorkouts as onFetchLikedWorkoutsAction,
  onFetchCompletedWorkouts as onFetchCompletedWorkoutsAction,
  onFetchScheduledWorkouts as onFetchScheduledWorkoutsAction,
} from '../redux/actions'
import {
  likedWorkoutSelector,
  completedWorkoutSelector,
  scheduledWorkoutSelector,
} from '../redux/selectors'
import { PageRendererProps } from 'gatsby'
import ScheduleComponent from '../components/live/schedule'
import { withErrorHandler } from '../components/errorHandler'

const LIVE_FEATURE_TOGGLE =
  process.env.GATSBY_LIVE_FEATURE_TOGGLE === 'true' ? true : false

class Profile extends PureComponent<Props> {
  componentDidMount() {
    const {
      onFetchAthleteProfile,
      onFetchLikedWorkouts,
      onFetchCompletedWorkouts,
      onFetchScheduledWorkouts,
    } = this.props
    onFetchAthleteProfile()
    onFetchLikedWorkouts()
    onFetchCompletedWorkouts()
    onFetchScheduledWorkouts()
  }

  hasProfilePicture = (): boolean => {
    const { profile } = this.props
    if (
      profile &&
      profile?.profilePictureUrl !== '' &&
      profile?.profilePictureUrl
    ) {
      return true
    }
    return false
  }

  render() {
    session.redirectIfNotLoggedIn(HOME_ROUTE)
    const {
      profile,
      likedWorkouts,
      completedWorkouts,
      scheduledWorkouts,
    } = this.props

    return (
      <Layout>
        <Section>
          {profile && (
            <S.HeaderContainer>
              {this.hasProfilePicture() ? (
                <S.ProfilePicture src={profile?.profilePictureUrl} />
              ) : (
                <DefaultAvatar
                  width={100}
                  userId={profile?.userId || 0}
                  initials={`${profile?.firstName.slice(
                    0,
                    1,
                  )}${profile?.lastName.slice(0, 1)}`}
                />
              )}

              <S.HeaderInfo>
                <S.ProfilePrimary>{`${profile?.firstName} ${profile?.lastName}`}</S.ProfilePrimary>
                {profile?.location && (
                  <S.ProfileSecondary>{profile?.location}</S.ProfileSecondary>
                )}
              </S.HeaderInfo>
            </S.HeaderContainer>
          )}

          {!!!likedWorkouts.length &&
            !!!completedWorkouts.length &&
            !!!scheduledWorkouts.length && (
              <S.SectionWrap>
                <S.ProfileSecondary>
                  {`Welcome to your ${CLIENT_NAME} profile!`}
                </S.ProfileSecondary>
              </S.SectionWrap>
            )}

          {LIVE_FEATURE_TOGGLE && (
            <ScheduleComponent
              location={this.props.location}
              userProfileId={session.getId() as number}
              fullWidth={true}
            />
          )}

          {!!likedWorkouts.length && (
            <S.SectionWrap>
              <S.SectionHeader>Liked Workouts</S.SectionHeader>
              <WorkoutsRow workouts={likedWorkouts} />
            </S.SectionWrap>
          )}
          {!!scheduledWorkouts.length && (
            <S.SectionWrap>
              <S.SectionHeader>Scheduled Workouts</S.SectionHeader>
              <WorkoutsRow workouts={scheduledWorkouts} isLargerRow />
            </S.SectionWrap>
          )}
          {!!completedWorkouts.length && (
            <S.SectionWrap>
              <S.SectionHeader>Completed Workouts</S.SectionHeader>
              <WorkoutsRow workouts={completedWorkouts} isLargerRow />
            </S.SectionWrap>
          )}
        </Section>
      </Layout>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  profile: state.entities.athleteProfileReducer.profile,
  likedWorkouts: likedWorkoutSelector(state),
  completedWorkouts: completedWorkoutSelector(state),
  scheduledWorkouts: scheduledWorkoutSelector(state),
})

const mapDispatchToProps = {
  onFetchAthleteProfile: onFetchAthleteProfileAction.request,
  onFetchLikedWorkouts: onFetchLikedWorkoutsAction.request,
  onFetchCompletedWorkouts: onFetchCompletedWorkoutsAction.request,
  onFetchScheduledWorkouts: onFetchScheduledWorkoutsAction.request,
}

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  PageRendererProps

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorHandler(Profile))
